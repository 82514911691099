import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
// import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'What tech do we specialize in?',
    content: () => (
      <>
        We have experience with and support most cloud technologies like AWS, Google Cloud, Azure, Docker, Jenkins, and Kubernetes.
        We also support most infrastructure-as-code tools like Ansible, Terraform, and CloudFormation
      </>
    ),
  },
  {
    title: 'Where do we fit on your team?',
    content: () => (
      <>
        Simply DevOps is all about flexibility. If you need us to build you a new infrastructure or simply
        finish a project/task, we are here for you. We truly want to be your one stop DevOps/Cloud technology shop. 
      </>
    ),
  },
  {
    title: 'Can we move physical/legacy infrastructure into the cloud and vice versa?',
    content: () => (
      <>
        The short and direct answer is yes! We can easily migrate existing systems into the cloud.
        Different companies have different infrastructure requirements, and we love challenges.
      </>
    ),
  },
  {
    title: 'Can we modernize your app?',
    content: () => (
      <>
        Sick and tired of hearing all these fancy terms like Docker, Kubernetes, Terraform, and wondering
        how to get there? That is where we come in, we can take any application stack and modernize it.
      </>
    ),
  },
  {
    title: 'How do we charge?',
    content: () => (
      <>
        Paying Hourly is so last decade. For each project, we offer flat rate for every project we do.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
